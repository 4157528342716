import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "socket.io-client";
import { motion, AnimatePresence } from "framer-motion";
import { gimmick } from "../effects/coins";
import iceCreamIcon from "../assets/ikony_lody.svg"
import sandwichIcon from "../assets/ikony_kanapka.svg"
import waffleIcon from "../assets/ikony_gofry.svg"
import arrowIcon from "../assets/nowa_strzałka.svg"
import iceCreamIconOutline from "../assets/ikony z obrysem-1.svg"
import sandwichIconOutline from "../assets/ikony z obrysem-2.svg"
import waffleIconOutline from "../assets/ikony z obrysem-3.svg"

const wsUrl = "https://ws.scool-corner-web-api.pl";
//const wsUrl = "http://localhost:3001";

const socket = io(wsUrl, {
  withCredentials: true,
  transports: ['websocket', 'polling']
});

function Home() {
const [ListOfDonations, setListOfDonations] = useState([]);
const [ListOfWithdrawals, setListOfWithdrawals] = useState([]);
const [ListOfTransfers, setListOfTransfers] = useState([]);
const [DonationAmounts, setDonationAmounts] = useState([]);
const [showDonateAnimation, setShowDonateAnimation] = useState(false);
const [showWithdrawAnimation, setShowWithdrawAnimation] = useState(false);
const [currentSchoolSubject, setCurrentSchoolSubject] = useState([]);
const [sponsorsDonations, setSponsorsDonations] = useState([]);
const [thisMonthWithdrawalsCounts, setThisMonthWithdrawalsCounts] = useState([]);
const [currentSponsorIndex, setCurrentSponsorIndex] = useState(0);

const fetchDonationsAndWithdrawals = () => {
  axios.get(`${wsUrl}/donations`).then((response) => {
    response.data.forEach((item) => {
      item.donationOrWithdrawal = 'donation'
    });
    setListOfDonations(response.data);
  });
  axios.get(`${wsUrl}/withdrawals`).then((response) => {
    response.data.forEach((item) => {
      item.donationOrWithdrawal = 'withdrawal'
    });
    setListOfWithdrawals(response.data);
  });
}

const fetchCurrentSchoolSubject = () => {
  axios.get(`${wsUrl}/school-subjects`).then((response) => {
    setCurrentSchoolSubject(response.data);
  });
};

const fetchThisMonthWithdrawalsCounts = () => {
  axios.get(`${wsUrl}/withdrawals/this-month-count`).then((response) => {
    setThisMonthWithdrawalsCounts(response.data);
  });
};

const fetchDonationAmounts = () => {
  axios.get(`${wsUrl}/donations/amounts`).then((response) => {
    setDonationAmounts(response.data);
  });
};

const fetchSponsorsDonations = () => {
  axios.get(`${wsUrl}/donations/sponsors`).then((response) => {
    setSponsorsDonations(response.data);
  });
};

const handleNewDonation = (newDonationData) => {
  if(ListOfTransfers.length < 10) {
    setListOfTransfers((prevList) => [newDonationData, ...prevList]);
  } else {
    setListOfTransfers((prevList) => [newDonationData, ...prevList.slice(0, -1)]);
  }

  setShowWithdrawAnimation(false);
  setShowDonateAnimation(false);
  setShowDonateAnimation(true);
  setTimeout(() => {
    setShowDonateAnimation(false);
  }, 30000);
  fetchSponsorsDonations();
  fetchDonationAmounts();
  fetchCurrentSchoolSubject();
  console.log(ListOfTransfers)
};

const handleNewWithdrawal = (newWithdrawalData) => {
  if(ListOfTransfers.length < 19) {
    setListOfTransfers((prevList) => [newWithdrawalData, ...prevList]);
  } else {
    setListOfTransfers((prevList) => [newWithdrawalData, ...prevList.slice(0, -1)]);
  }

  setShowWithdrawAnimation(false);
  setShowDonateAnimation(false);
  setShowWithdrawAnimation(true);
  setTimeout(() => {
    setShowWithdrawAnimation(false);
  }, 30000);
  fetchDonationAmounts();
  fetchCurrentSchoolSubject();
  fetchThisMonthWithdrawalsCounts();
  console.log(ListOfTransfers)
};


useEffect(() => {
  socket.on("newDonation", handleNewDonation);
  socket.on("newWithdrawal", handleNewWithdrawal);
  fetchDonationsAndWithdrawals();
  fetchSponsorsDonations();
  fetchDonationAmounts();
  fetchCurrentSchoolSubject();
  fetchThisMonthWithdrawalsCounts();

  return () => {
    socket.off("newDonation", handleNewDonation);
    socket.off("newWithdrawal", handleNewWithdrawal);
  };
}, []);

useEffect(() => {
  setListOfTransfers([...ListOfDonations, ...ListOfWithdrawals]
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .splice(-10))
}, [ListOfDonations, ListOfWithdrawals]);

useEffect(()=>{
  document.getElementById('body').style.backgroundImage = ``;
  document.getElementById('body').style.backgroundColor = `white`;
});

useEffect(() => {
  const interval = setInterval(() => {
    setCurrentSponsorIndex((prevIndex) => (prevIndex + 1) % sponsorsDonations.length);
  }, 30000); 

  return () => clearInterval(interval);
}, [sponsorsDonations.length]);

const formatDecimal = (value) => {
  const num = Number(value);
  return num.toString();
}

const formatDecimalFixed2 = (value) => {
  const num = Number(value).toFixed(2);
  return num.toString();
}

const polishPlural = (singularNominativ, pluralNominativ, pluralGenitive, value) => {
  if (value === 1) {
      return singularNominativ;
  } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
      return pluralNominativ;
  } else {
      return pluralGenitive;
  }
}

  return (
    <div className="sticky">
      <div className="bankInfo">
          <div className="bankInfo-top">
            <h3>SKARBIEC ABSOLWENTÓW DLA UCZNIÓW </h3>
          </div>
        <div className="bankInfo-content">
          <div className="bankInfo-left">
            <div className="bankInfo-titleWithArrow">
              <h3>PRZEDMIOT MIESIĄCA</h3>
              <img className="strzalka strzalka1" src={`${arrowIcon}`} alt=""></img>
            </div>
              <h2 className="bankInfo-przedmiotMiesiaca">{currentSchoolSubject[0] ? currentSchoolSubject[0].name.toUpperCase() : "ZAPYTAJ NAS"}</h2>
            <div className="bankInfo-titleWithArrow">
              <h3>DO TEJ PORY ZEBRALIŚMY</h3>
              <img className="strzalka strzalka2" src={`${arrowIcon}`} alt=""></img>
            </div>
              <h2>{DonationAmounts.totalAmount ? formatDecimalFixed2(DonationAmounts.totalAmount) : "0"} ZŁ</h2>
            <div className="bankInfo-titleWithArrow">
            <h3>AKTUALNIE W SKARBCU</h3>
              <img className="strzalka strzalka3" src={`${arrowIcon}`} alt=""></img>
            </div>
              <h2 className="no-margin">{DonationAmounts.totalRemainingAmount ? formatDecimalFixed2(DonationAmounts.totalRemainingAmount) : "0"} ZŁ</h2> 
          </div>
          <div className="bankInfo-right">
            <div className="bankInfo-titleWithArrow">
              <h3>W TYM MIESIĄCU WYDALIŚMY UCZNIOM</h3>
              <img className="strzalka strzalka4" src={`${arrowIcon}`} alt=""></img>
            </div>
            <div className="bankInfo-right-product-line">
              <img src={`${iceCreamIcon}`} alt=""></img>
              <h2>{thisMonthWithdrawalsCounts.iceCreamCount ?? 0}</h2>
              <h3>{polishPlural('PORCJA LODÓW', 'PORCJE LODÓW', 'PORCJI LODÓW', thisMonthWithdrawalsCounts.iceCreamCount ?? 0)}</h3>
            </div>
            <div className="bankInfo-right-product-line">  
              <img src={`${sandwichIcon}`} alt=""></img>
              <h2>{thisMonthWithdrawalsCounts.sandwichCount ?? 0}</h2>
              <h3>{polishPlural("S'COOL KANAPKA", "S'COOL KANAPKI", "S'COOL KANAPEK", thisMonthWithdrawalsCounts.sandwichCount ?? 0)}</h3>
            </div>
            <div className="bankInfo-right-product-line">
              <img src={`${waffleIcon}`} alt=""></img>
              <h2>{thisMonthWithdrawalsCounts.waffleCount ?? 0}</h2>
              <h3>{polishPlural("GOFR", "GOFRY", "GOFRÓW", thisMonthWithdrawalsCounts.waffleCount ?? 0)}</h3>
            </div>
            <div className="bankInfo-titleWithArrow">
              <h3 className="bankInfo-sumOfGifts">KTÓRYCH ŁĄCZNA WARTOŚĆ TO {formatDecimal(thisMonthWithdrawalsCounts.sumOfPrices) ?? 0} ZŁ</h3>
            </div>
          </div>
        </div>
        <div className="bankInfo-footer">
          <div className = 'bankInfo-footer-title'>
            <h3>GŁÓWNI PARTNERZY: </h3>
          </div>
          <div className = 'bankInfo-footer-sponsors'>
            {sponsorsDonations.map((value, key) => (
              <h3
                key={key}
                className={`sponsor ${key === currentSponsorIndex ? 'visible' : 'hidden'}`}
              >
                {value.name}{" - "}{formatDecimalFixed2(value.amount)} ZŁ
              </h3>
              ))}
          </div>
        </div>
      </div>
      <div className="bubbles">
        {ListOfTransfers.map((value, key) => {
          return (
            <div key={key} className={`bubble ${value.donationOrWithdrawal === 'withdrawal' ? 'bubbleWithdraw' : ''}`}>
              <div className="donationAmount">
                <p>
                  {value.name.toUpperCase()}
                  <br />
                  {
                  value.donationOrWithdrawal === 'donation' 
                  ? `${formatDecimal(value.amount)} ZŁ` 
                  : value.productName === 'icecream' 
                    ? <img src={`${iceCreamIconOutline}`} alt=""></img> 
                    : value.productName === 'sandwich' 
                      ? <img src={`${sandwichIconOutline}`} alt=""></img> 
                      : value.productName === 'waffle' 
                        ? <img src={`${waffleIconOutline}`} alt=""></img>
                        : ""
                  }
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <AnimatePresence>
        {showDonateAnimation && (
          <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            transition={{duration: 3}}
            exit={{ opacity: 0}}
          >
            <div className="coinRainOpacity">

            </div>
            <div className="coinRain">
                <h3 className="coinRain-start coinRain-start-donation">
                  {ListOfTransfers[0] ? ListOfTransfers[0].name.toUpperCase() : "błąd"}{" WŁAŚNIE WPŁACIŁ/A "}
                </h3>
                <h2 className="coinRain-middle">
                  {ListOfTransfers[0] ? formatDecimal(ListOfTransfers[0].amount) : "błąd"} ZŁ
                </h2>
                <h3 className="coinRain-end">
                  dziękujemy !
                </h3>
                
            {gimmick('coinRain')}
            </div>
          
          </motion.div>
        )}
        {showWithdrawAnimation && (
          <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            transition={{duration: 3}}
            exit={{ opacity: 0}}
          >
          <div className="coinRainOpacity">

          </div>
          <div className="coinRain"> 
              <h3 className="coinRain-start">
                {ListOfTransfers[0] ? ListOfTransfers[0].name.toUpperCase() : "błąd"}{' WŁAŚNIE ODEBRAŁ/A '}
                {ListOfTransfers[0] 
                  ? ListOfTransfers[0].productName === 'icecream' 
                    ? 'PYSZNEGO LODA!' 
                    : ListOfTransfers[0].productName === 'sandwich' 
                      ? 'PYSZNĄ EKO KANAPKĘ!' 
                      : ListOfTransfers[0].productName === 'waffle'
                        ? 'PYSZNEGO GOFRA!' 
                        : "błąd" : "błąd"} <br></br>
                DOŁOŻYLI SIĘ: {ListOfTransfers[0] ? ListOfTransfers[0].donorsNames.toString().toUpperCase().replaceAll(',', ', ') : "błąd"}
              </h3>
              <h2 className="coinRain-middle coinRain-img">
                {ListOfTransfers[0] 
                  ? ListOfTransfers[0].productName === 'icecream' 
                    ? <img src={`${iceCreamIconOutline}`} alt=""></img> 
                    : ListOfTransfers[0].productName === 'sandwich' 
                      ? <img src={`${sandwichIconOutline}`} alt=""></img> 
                      : ListOfTransfers[0].productName === 'waffle'
                        ? <img src={`${waffleIconOutline}`} alt=""></img> 
                        : "błąd" : "błąd"}
              </h2>
              <h3 className="coinRain-end">
                smacznego !
              </h3>
              
          {gimmick('coinRain')}
          </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Home;
